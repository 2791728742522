<template>
  <q-form @submit="onSubmit" ref="formRef" @reset="onReset" class="row">
    <div
      class="col-12 q-px-sm q-pt-md text-h5"
      v-if="
        fieldToggles?.companyName ||
        fieldToggles?.legalForm ||
        fieldToggles?.contactPerson ||
        fieldToggles?.contactFunction
      "
    >
      Bedrijfgegevens
    </div>
    <div :class="colSize" class="q-px-sm q-my-sm">
      <q-input
        v-if="fieldToggles?.companyName"
        dense
        outlined
        :modelValue="companyName"
        @change="companyName = $event"
        label="Bedrijfsnaam"
        :rules="validate('required')"
      />
    </div>
    <div :class="colSize" class="q-px-sm q-my-sm">
      <q-select
        v-if="fieldToggles?.legalForm"
        filled
        dense
        v-model="legalFormModel"
        :options="legalFormOptions"
        @change="legalFormModel = $event.value"
        label="Rechtsvorm"
        :rules="validate('required')"
      />
    </div>
    <div :class="colSize" class="q-px-sm q-my-sm">
      <q-input
        v-if="fieldToggles?.contactPerson"
        dense
        outlined
        :modelValue="contactPerson"
        @change="contactPerson = $event"
        label="Contactpersoon"
        :rules="validate('required')"
      />
    </div>
    <div :class="colSize" class="q-px-sm q-my-sm">
      <q-input
        v-if="fieldToggles?.contactFunction"
        dense
        outlined
        :modelValue="contactFunction"
        @change="contactFunction = $event"
        label="Functie"
        :rules="validate('required')"
      />
    </div>
    <div class="col-12">
      <Adress
        v-if="component_settings?.Adress?.enabled ?? true"
        :settings="component_settings?.Adress ?? {}"
      />
    </div>
    <div class="col-12 q-px-sm">
      <q-input
        v-if="fieldToggles?.country"
        dense
        outlined
        :modelValue="country"
        @change="country = $event"
        label="Land"
        :rules="validate('required')"
      />
    </div>
    <div class="col-12">
      <Contact
        v-if="component_settings?.Contact?.enabled ?? true"
        :settings="component_settings?.Contact ?? {}"
      />
    </div>
    <div class="col-12">
      <Personal
        v-if="component_settings?.Personal?.enabled ?? true"
        :settings="component_settings?.Personal ?? {}"
      />
    </div>
    <div
      class="col-12 q-px-sm q-pt-md text-h5"
      v-if="fieldToggles?.totalUsers || fieldToggles?.notes"
    >
      Overige
    </div>
    <div class="col-12 q-px-sm q-my-sm">
      <q-input
        v-if="fieldToggles?.totalUsers"
        dense
        outlined
        type="number"
        :modelValue="totalUsers"
        @change="totalUsers = $event"
        label="Aantal gebruikers"
        min="0"
        :rules="validate('required')"
      />
    </div>
    <div class="col-12 q-px-sm q-my-sm">
      <q-input
        v-if="fieldToggles?.notes"
        dense
        outlined
        type="textarea"
        :modelValue="notes"
        @change="notes = $event"
        label="Opmerkingen"
      />
    </div>
    <div class="q-ma-sm">
      <q-btn
        label="Plannen"
        type="submit"
        color="primary"
        :disable="(clientOid != '' && isNLIFlow) || appointmentLoadState"
        :loading="appointmentLoadState"
      />
      <q-tooltip
        class="bg-primary text-body2"
        :offset="[50, -95]"
        v-if="clientOid != '' && isNLIFlow"
      >
        Er staat al een afspraak gepland voor dit record.
      </q-tooltip>
      <q-btn
        label="Copy URL"
        color="primary"
        @click="copyURL(redirectUrl)"
        v-if="redirectUrl != '' && isNLIFlow"
        class="q-ml-sm"
      />
    </div>
  </q-form>
</template>

<script setup>
import { ref, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { defineAsyncComponent, watch } from "vue";
import { validate } from "../../plugins/validation.js";
import { useQuasar } from "quasar";
import { set_product } from "../../plugins/products.js";
import { copyToClipboard } from "quasar";

const Adress = defineAsyncComponent(() =>
  import("@/components/ProspectInformation/Adress.vue")
);
const Contact = defineAsyncComponent(() =>
  import("@/components/ProspectInformation/Contact.vue")
);
const Personal = defineAsyncComponent(() =>
  import("@/components/ProspectInformation/Personal.vue")
);

//* Props
// eslint-disable-next-line no-unused-vars, no-undef
const props = defineProps({
  component_settings: Object,
  settings: Object,
});

//* VueUse
const $q = useQuasar();
const $store = useStore();

//* Refs
const formRef = ref(null);
const legalFormOptions = ref([
  {
    label: "Eenmanszaak",
    value: "eenmanszaak",
  },
  {
    label: "BV",
    value: "bv",
  },
  {
    label: "VOF",
    value: "vof",
  },
  {
    label: "Stichting",
    value: "stichting",
  },
  {
    label: "Vereniging",
    value: "vereniging",
  },
  {
    label: "NV",
    value: "nv",
  },
]);
const redirectUrl = ref("");
const isNLIFlow = ref(props.settings?.NLIFlow ?? false);
const fieldToggles = ref(props.settings?.fieldToggles ?? {});
const showCopyButton = ref(false);
const appointmentLoadState = ref(false);

//* Computed
const products = computed(() => $store.getters.products.list_products_array[0]);
const colSize = computed(() => ($q.screen.lt.md ? "col-12" : "col-6"));
const companyName = computed({
  get() {
    return $store.getters.record?.Bedrijfsnaam ?? "";
  },
  set(newVal) {
    $store.dispatch("updateRecord", {
      key: "Bedrijfsnaam",
      value: newVal,
    });
  },
});
const legalFormModel = computed({
  get() {
    return $store.getters.projectSpecific["Rechtsvorm"] ?? "Eenmanszaak";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "Rechtsvorm",
      value: newVal,
    });
  },
});
const country = computed({
  get() {
    return $store.getters.projectSpecific["Land"] ?? "";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "Land",
      value: newVal,
    });
  },
});
const contactPerson = computed({
  get() {
    return $store.getters.projectSpecific["Contactpersoon"] ?? "";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "Contactpersoon",
      value: newVal,
    });
  },
});
const contactFunction = computed({
  get() {
    return $store.getters.projectSpecific["Functie"] ?? "";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "Functie",
      value: newVal,
    });
  },
});
const totalUsers = computed({
  get() {
    return $store.getters.projectSpecific["Aantal gebruikers"] ?? 0;
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "Aantal gebruikers",
      value: newVal,
    });
  },
});
const notes = computed({
  get() {
    return $store.getters.projectSpecific["Opmerkingen"] ?? "";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "Opmerkingen",
      value: newVal,
    });
  },
});
const campaignId = computed(() => {
  return $store.getters.steamVelden["campagne"] ?? "";
});
const clientOid = computed({
  get() {
    return $store.getters.projectSpecific["clientOid"] ?? "";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "clientOid",
      value: newVal,
    });
  },
});

watch(
  () => clientOid.value,
  (clientOidNew) => {
    if (clientOidNew != "" && redirectUrl.value == "") {
      getRedirectURL();
    }
  },
  { immediate: clientOid.value != "" && typeof clientOid.value != "undefined" }
);

//* Method
function onSubmit() {
  formRef.value.validate().then((success) => {
    if (success) {
      appointmentLoadState.value = true;
      let productObj = { id: products.value.key, product: products.value };
      set_product(productObj).then(() => {
        $store
          .dispatch("sendOffers")
          .then((response) => {
            if (response.data.error) {
              $q.notify({
                type: "negative",
                message: response.data.message,
                position: "top",
                timeout: 10000,
                progress: true,
              });
              return;
            }
            $store.dispatch("getRecord").then(() => {
              if (!isNLIFlow.value) {
                $store.dispatch("updateStatus", {
                  status: props.settings?.status,
                  reason: props.settings?.reason,
                });
                $q.notify({
                  type: "positive",
                  message: "Afspraak is gemaakt!",
                  position: "top",
                  timeout: 10000,
                  progress: true,
                });
                getRedirectURL();
                appointmentLoadState.value = false;
              } else {
                $store
                  .dispatch("NLIcreateAppointment", {
                    record_id:
                      $store.getters.projectSpecific["OP_record_ids"][0],
                  })
                  .then((response) => {
                    clientOid.value = response.data.clientOid;
                    // getRedirectURL();
                    appointmentLoadState.value = false;
                  })
                  .catch((err) => {
                    if (err.response.data.result == "error") {
                      $q.notify({
                        type: "negative",
                        message: err.response.data.message,
                        position: "top",
                        timeout: 10000,
                        progress: true,
                      });
                      appointmentLoadState.value = false;
                      return;
                    }
                  });
              }
            });
          })
          .catch((err) => {
            if (err.response.data.error) {
              $q.notify({
                type: "negative",
                message: err.response.data.message,
                position: "top",
                timeout: 10000,
                progress: true,
              });
              appointmentLoadState.value = false;
              return;
            }
          });
      });
    }
  });
}
function getRedirectURL() {
  redirectUrl.value = props.settings?.url;
  let replaceArray = redirectUrl.value.match(/{{\w*}}?/gm);
  replaceArray.forEach((replace) => {
    switch (replace) {
      case "{{campaignId}}":
        redirectUrl.value = redirectUrl.value.replace(
          replace,
          campaignId.value
        );
        break;
      case "{{recordId}}":
        redirectUrl.value = redirectUrl.value.replace(
          replace,
          $store.getters.record?.id
        );
        break;
      case "{{OPRecordId}}":
        redirectUrl.value = redirectUrl.value.replace(
          replace,
          $store.getters.projectSpecific["OP_record_ids"][0]
        );
        break;
      case "{{companyName}}":
        redirectUrl.value = redirectUrl.value.replace(
          replace,
          companyName.value
        );
        break;
      case "{{contactPerson}}":
        redirectUrl.value = redirectUrl.value.replace(
          replace,
          contactPerson.value
        );
        break;
      case "{{totalUsers}}":
        redirectUrl.value = redirectUrl.value.replace(
          replace,
          totalUsers.value
        );
        break;
      case "{{email}}":
        redirectUrl.value = redirectUrl.value.replace(
          replace,
          $store.getters.record?.Email
        );
        break;
      case "{{clientOid}}":
        redirectUrl.value = redirectUrl.value.replace(replace, clientOid.value);
        break;
    }
  });
  if (isNLIFlow.value) {
    $q.notify({
      type: "positive",
      message:
        "URL is aangemaakt! Je kan deze kopieren door op 'COPY URL' te klikken!",
      position: "top",
      timeout: 10000,
      progress: true,
    });
    showCopyButton.value = true;
  } else {
    window.location.replace(redirectUrl.value);
  }
}
function copyURL(URL) {
  copyToClipboard(URL)
    .then(() => {
      $q.notify({
        type: "positive",
        message:
          "URL is gekopieerd! Plak de URL in je browser naar keuze en ga daar verder!",
        position: "top",
        timeout: 10000,
        progress: true,
      });
    })
    .catch(() => {
      $q.notify({
        type: "warning",
        message:
          "URL kon niet gekopieerd worden! Vermoedelijk staat dit uitgeschakeld in je browser instellingen.",
        position: "top",
        timeout: 10000,
        progress: true,
      });
    });
}
</script>

<style lang="sass"></style>
